<template>
  <div>
    <nav class="panel">
      <p class="panel-heading">Nouveau lien</p>
      <div class="panel-block">
        <form @submit.prevent="ajouterLien" class="field has-addons" style="width:100%">
          <div class="control" style="width:100%">
            <input class="input" v-model="url" type="url" placeholder="URL de la page" />
          </div>
          <div class="control">
            <button class="button is-info" :class="loadingClass">Ajouter</button>
          </div>
        </form>
      </div>
    </nav>
    <nav class="panel">
      <p class="panel-heading">Liens</p>
      <div class="panel-block">
        <p class="control has-icons-left">
          <input
            class="input"
            type="text"
            placeholder="Rechercher"
            v-model="recherche"
            @keyup="rechercher"
          />
          <span class="icon is-left">
            <i class="fas fa-search" aria-hidden="true"></i>
          </span>
        </p>
      </div>

      <div class="partages">
        <div v-if="!ready" class="loader-wrapper is-active">
          <div class="loader is-loading"></div>
        </div>
        <template v-if="$store.getters.partages">
          <template v-for="partage in $store.getters.partages">
            <!--  is-active -->
            <router-link
              :to="{name:'Partage',params : {id:partage.id}}"
              :key="partage.id"
              class="panel-block"
              :class="partage.id == $store.getters.partageId ? 'is-active' : ''"
            >
              <span class="panel-icon">
                <img :src="favicon(partage)" />
                <!-- <i class="fas fa-link" aria-hidden="true"></i> -->
              </span>
              {{partage.titre}}
            </router-link>
          </template>
        </template>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Partages",
  data() {
    return {
      url: "",
      recherche: "",
      sto: false,
    };
  },
  computed: {
    ready() {
      return this.$store.getters.partages !== false;
    },
  },
  methods: {
    favicon(partage) {
      let domaine = "sopress.net";
      if (partage.url.includes("sofoot.com")) {
        domaine = "www.sofoot.com";
      }
      return "https://" + domaine + "/favicon.ico";
    },
    rechercher() {
      clearTimeout(this.sto);
      this.sto = setTimeout(() => {
        if (this.recherche) {
          this.$store.dispatch("chercherPartages", this.recherche);
        } else {
          this.$store.dispatch("chargerPartages");
        }
      }, 500);
    },
    ajouterLien() {
      if (this.url) {
        this.loadingStart();
        tools
          .get("meta/", { params: { url: this.url, withContent: true } })
          .then((response) => {
            let meta = response.data;
            let data = {
              titre: meta.titre,
              slug : slugify(meta.titre, { lower: true, strict: true }),
              url: meta.url,
              description: meta.description,
              image:meta.image.url
            };
            this.api
              .post("table", { nom: "partages", data })
              .then((response) => {
                this.loadingEnd();
                this.url = "";
                this.$router.push({
                  name: "Partage",
                  params: { id: response.data.id },
                });
              });

            /*            let imageContent = new Parse.File(meta.image.name, {
              base64: meta.image.content,
            });
            let Partages = Parse.Object.extend("Partages");
            let partage = new Partages();
            partage.set("url", meta.url);
            partage.set("titre", meta.titre);
            partage.set(
              "slug",
              slugify(meta.titre, { lower: true, strict: true })
            );
            partage.set("description", meta.description);
            partage.set("image", imageContent);

            partage.save().then(() => {
              this.loadingEnd();
              this.url = "";
              this.$router.push({
                name: "Partage",
                params: { id: partage.id },
              });
            });*/
          });
      }
    },
  },
};
</script>

<style>
.partages {
  max-height: 500px;
  overflow-y: auto;
}
.partages {
  position: relative;
}
</style>