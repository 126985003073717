<template>
  <div class="section">
    <div class="columns">
      <div class="column">
        <partage v-if="$store.getters.partages" />
      </div>
      <div class="column is-4">
        <partages />
      </div>
    </div>
  </div>
</template>

<script>
import Partage from "../components/Partage.vue";
import Partages from "../components/Partages.vue";
export default {
  components: { Partages, Partage },
  data() {
    return {};
  },
  watch: {
    $route() {
      this.$store.dispatch("chargerPartages");
    },
  },
  mounted() {
    this.checkSession();
    if (!this.$store.getters.isConnected) {
      Sso.logIn(this.$route.query.jwt, { title: "Partages" }, (data) => {
        this.$store.commit("setSession", data);
        this.$router.push("/");
      });
    } else {
      this.$store.dispatch("chargerPartages");
    }
  },
};
</script>
