<template>
  <form @submit.prevent="sauverPartage" enctype="multipart/form-data" v-if="partage.id">
    <div v-if="!ready" class="loader-wrapper is-fullscreen is-active">
      <div class="loader is-loading"></div>
    </div>

    <div class="columns">
      <div class="column">
        <div class="card">
          <div class="card-image">
            <figure class="image">
              <img :src="partageImage" />
              <input type="file" ref="file" @change="changerImage" />
              <button class="button is-primary" type="button" :class="loadingClass">Changer l'image</button>
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <p class="title is-4">{{partage.titre}}</p>
              </div>
            </div>

            <div class="content">
              {{partage.description}}
              <br />
              <time>{{partage.createdAt}}</time>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="field">
            <label class="label">Url à partager</label>
            <div class="control">
              <input
                class="input"
                style="font-size:12px"
                onclick="this.select()"
                onfocus="this.select()"
                @focus="copyTextToClipboard(partageUrl);copier=true"
                type="text"
                :value="partageUrl"
                readonly
              />
            </div>
            <p class="help is-link" v-if="copier">Adresse copiée dans le presse papier !</p>
            <p class="help" v-else>Cliquer sur le lien pour copier l'adresse</p>
          </div>
          <hr />
          <div class="field">
            <label class="label">Adresse de la page cible</label>
            <div class="control">
              <input class="input" type="text" disabled v-model="partage.url" readonly />
            </div>
          </div>

          <div class="field">
            <label class="label">Titre</label>
            <div class="control">
              <input class="input" type="text" v-model="partage.titre" />
            </div>
          </div>

          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <textarea class="textarea" v-model="partage.description"></textarea>
            </div>
          </div>

          <div class="field is-grouped">
            <div class="control">
              <button class="button is-primary" :class="loadingClass">
                <span class="icon">
                  <i class="fas fa-save"></i>
                </span>
                <span>Enregistrer</span>
              </button>
            </div>
          </div>
          <div class="field is-grouped">
            <div class="control">
              <!-- <button
                type="button"
                class="button is-small is-danger is-outlined"
                @click="effacerPartage"
              >
                <span class="icon">
                  <i class="fas fa-trash"></i>
                </span>
                <span>Supprimer</span>
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import base64 from "base-64";
import utf8 from "utf8";
export default {
  name: "Partage",
  data() {
    return {
      copier: false,
      partage: {
        id: false,
        titre: "",
        description: "",
        url: "",
        slug: "",
        image: "",
      },
    };
  },
  watch: {
    $route() {
      this.chargement();
    },
  },
  mounted() {
    this.chargement();
  },
  computed: {
    ready() {
      return this.$store.getters.partage !== false;
    },

    partageImage() {
      if (this.partage && this.partage.image) {
        return this.partage.image;
      }
      /*      return this.partage && this.partage.image
        ? this.partage.image.url
        : false;*/
    },
    partageUrl() {
      let domaine = "sopress.net";
      if (this.partage.url.includes("sofoot.com")) {
        domaine = "www.sofoot.com";
      }
      return (
        "https://" +
        domaine +
        "/partages/" +
        this.partage.id +
        "-" +
        this.partage.slug
      );
    },
  },
  methods: {
    changerImage() {
      let file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append("nom", 'partages');
      formData.append("image", file);
      formData.append("id", this.partage.id );

      this.api.post("table-upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(response => {
        this.partage.image = response.data.image
        this.sauverPartage();
      });
    },
    sauverPartage() {
      this.loadingStart();

      this.api
        .patch("table", {
          nom: "partages",
          where: { id: this.partage.id },
          data: {
            titre:this.partage.titre,
            url:this.partage.url,
            description:this.partage.description
          },
        })
        .then((response) => {
          this.$store.dispatch("chargerPartages");
          this.loadingEnd();
        });
      /*
      let Partages = Parse.Object.extend("Partages");
      let partage = new Partages();
      partage.set("id", this.partage.id);
      partage.set("url", this.partage.url);
      partage.set("titre", this.partage.titre);
      partage.set("description", this.partage.description);
      let file = this.$refs.file.files[0];
      let imageContent;
      if (file) {
        imageContent = new Parse.File(file.name, file);
        partage.set("image", imageContent);
      }
      partage.save().then(() => {
        if (imageContent) {
          this.partage.image.url = imageContent._url;
        }
        this.$store.dispatch("chargerPartages");
        this.loadingEnd();
      });*/
    },
    setPartage() {
      this.partage.id = false;
      this.copier = false;
      if (this.$store.getters.partage) {
        this.partage.id = this.$store.getters.partage.id;
        this.partage.titre = this.$store.getters.partage.titre;
        this.partage.description = this.$store.getters.partage.description;
        this.partage.url = this.$store.getters.partage.url;
        this.partage.slug = this.$store.getters.partage.slug;
        this.partage.image = this.$store.getters.partage.image;
      }
    },
    chargement() {
      this.$store.dispatch("chargerPartage", {
        id: this.$route.params.id,
        callback: this.setPartage,
      });
    },
    effacerPartage() {
      if (confirm("Effacer ce lien ?")) {
        this.loadingStart();
        backend.delete(`Partages/${this.partage.id}`).then(() => {
          this.loadingEnd();
          this.$router.push({
            name: "Partage",
            params: { id: this.$store.getters.partages[0].id },
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
form {
  position: relative;
}
figure.image:hover {
  opacity: 0.8;
}
figure.image {
  [type="file"] {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  [type="file"] {
    opacity: 0;
    cursor: pointer;
  }
  button {
    position: absolute;
    right: 1em;
    bottom: 1em;
    z-index: 1;
  }
}
</style>